import { Injectable } from '@angular/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { EventService } from '../events/event.service';
import { NavController } from '@ionic/angular';
import { UserInfoService } from './user-info-service';
import { Semaphore } from 'src/app/utilities/semaphore/semaphore.utility';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import { AccessTokenModel, ChangeEmailAddressRequestModel, ChangePasswordRequestModel, ForgotPasswordRequestModel, LoginRequestModel, RefreshTokenRequestModel, RefreshTokenResponseModel, RequestOtpModel, RequestOtpResponseModel, ResetPasswordRequestModel, VerifyOtpRequestModel } from 'src/app/api/proxy/auth/authentication-models';
import { firstValueFrom, timeout } from 'rxjs';
import { differenceInSeconds, subMinutes } from 'date-fns';
import { BiometricLoginDetail, UserData } from './user-data.model';
import { Capacitor } from '@capacitor/core';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { PromptService } from '../promtp-service/prompt.service';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private NETWORK_TIMEOUT = 20000;

  private REFRESH_TIMEOUT = 15000;

  constructor(
    private events: EventService,
    private secureStorageService: SecureStorageService,
    public navCtrl: NavController,
    private _userInfoService: UserInfoService,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _localAccountService: AuthenticationLocalAccountService,
    private _systemLogService: SystemLogService,
    private _PromptService: PromptService
  ) {
    this.events.appPaused.subscribe(async () => {
      this.clearRefresh();
    });
  }


  public async init(): Promise<void> {
    await this.setupRefreshTimer();
  }

  public async tokenExpired(): Promise<boolean> {
    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.tokenExpiry && userInfo.access_token) {
      let dt = new Date(userInfo.tokenExpiry);
      dt = subMinutes(dt, 10); //expire the token 5 minutes in advance for server time skew
      const compareDate = new Date();
      const expired = dt <= compareDate;
      return expired;
    }
    return true; //no user object return true
  }

  public async token(): Promise<string | null> {
    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.access_token) {
      return userInfo.access_token;
    }
    return null;
  }

  public async saveBiometricLogin(countryCode: string, email: string, password: string) {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    const biometricLogin: BiometricLoginDetail = {
      username: email,
      password: password,
      countrycode: countryCode
    };
    await this.secureStorageService.set(SecureStorageKey.BiometricLogin, JSON.stringify(biometricLogin));
  }

  public async getBiometricLogin(): Promise<BiometricLoginDetail | null> {
    if (Capacitor.getPlatform() === 'web') {
      return null;
    }

    const userData = await this.secureStorageService.get(
      SecureStorageKey.BiometricLogin
    );

    if (!userData || userData.length === 0 || userData === '') {
      return null;
    }
    const userInfo = JSON.parse(userData) as BiometricLoginDetail;
    if (!userInfo) {
      return null;
    }

    return userInfo;
  }

  public async clearBiometricLogin(): Promise<void> {
    const bioData = await this.getBiometricLogin();
    if (bioData) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, '');
      await this.secureStorageService.remove(SecureStorageKey.BiometricLogin);
    }
  }

  public async login(emailAddress: string, pasw: string): Promise<boolean> {
    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration();
      if (!envConfig) {
        return false;
      }

      const loginRequest: LoginRequestModel = {
        applicationId: envConfig.authenticateOptions!.appId!,
        applicationScope: envConfig.authenticateOptions!.scope!,
        emailAddress: emailAddress,
        password: pasw,
      };

      let response: AccessTokenModel | null;

      try {
        response = await firstValueFrom(this._localAccountService.localAccountLoginUserPost({ body: loginRequest })
          .pipe(timeout(this.NETWORK_TIMEOUT)));

      } catch (error) {
        await this._PromptService.showNetworkConnectionError(error, 'login');
        return false;
      }

      if (response && response.access_token && response.access_token.length > 0 && !response.error) {
        const tokenResponse = response as AccessTokenModel;
        const token = this.parseJwt(tokenResponse.access_token!);
        // Convert `exp` to milliseconds and create a Date object for expiry
        const tokenExpiry = new Date(token.exp * 1000);
        // Subtract 2 minutes from the expiry time
        const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: renewTokenTime.toISOString(),
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions,
          emailVerified: tokenResponse.emailVerified
        };

        const oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        await this.logDebug('user logged in ' + JSON.stringify(userInfo));
        return true; // Login successful
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
    return false;
  }

  public async forgotPasswordRequest(email: string): Promise<boolean> {
    try {
      const model: ForgotPasswordRequestModel = { emailAddress: email };
      await firstValueFrom(this._localAccountService.localAccountForgotPasswordRequestPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'forgotPasswordRequest');
      return false;
    }
  }

  public async resetForgotPassword(email: string, password: string, otp: number): Promise<boolean> {
    try {
      const model: ResetPasswordRequestModel = { emailAddress: email, newPassword: password, otp: otp };
      await firstValueFrom(this._localAccountService.localAccountResetForgotPasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      await this.clearBiometricLogin();
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'resetForgotPassword');
      return false;
    }
  }

  public async changePassword(password: string) {
    try {
      const model: ChangePasswordRequestModel = { newPassword: password };
      await firstValueFrom(
        this._localAccountService.localAccountChangePasswordPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      if (Capacitor.isNativePlatform()) {
        const bioPassword = await this.getBiometricLogin();
        if (bioPassword) {
          await this.saveBiometricLogin(bioPassword.countrycode, bioPassword.username, password);
        }
      }
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'changePassword');
      return false;
    }
  }

  public async changeEmail(emailaddress: string) {
    try {
      const model: ChangeEmailAddressRequestModel = { newEmail: emailaddress };
      await firstValueFrom(
        this._localAccountService.localAccountChangeEmailPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      if (Capacitor.isNativePlatform()) {
        const bioPassword = await this.getBiometricLogin();
        if (bioPassword) {
          await this.saveBiometricLogin(bioPassword.countrycode, emailaddress, bioPassword.password);
        }
      }
      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'change email');
      return false;
    }
  }

  public async registerProfile(tokenResponse: AccessTokenModel): Promise<boolean> {
    try {

      if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
        const token = this.parseJwt(tokenResponse.access_token);
        // Convert `exp` to milliseconds and create a Date object for expiry
        const tokenExpiry = new Date(token.exp * 1000);
        // Subtract 2 minutes from the expiry time
        const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: renewTokenTime.toISOString(),
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions,
          emailVerified: tokenResponse.emailVerified
        };

        const oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      } else {
        throw new Error("Unexpected error occurred");
      }
    } catch (error) {
      await this._systemLogService.logError(error);
      return false;
    }
  }

  public async requestOTP(model: RequestOtpModel): Promise<RequestOtpResponseModel | null> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountRequestOtpPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'requestOTP');
      return null;
    }
  }

  public async verifyOTP(model: VerifyOtpRequestModel): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountVerifyOtpAsyncPost({ body: model }).pipe(timeout(this.NETWORK_TIMEOUT)));
      return response == true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'verifyOTP');
      return false;
    }
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  private lock = new Semaphore(1);

  public async refreshToken(timerRefresh: boolean): Promise<void> {
    await this.lock.callFunction(async () => {
      await this.refresh(timerRefresh);
    }, []);

  }

  public async logDebug(mesg: string) {
   /* const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.email && (userInfo.email.toLocaleLowerCase().startsWith('willie') || userInfo.email.toLocaleLowerCase().startsWith('ilse'))) {
      try {
        this._systemLogService.enableLogging();
        await this._systemLogService.logMessage('WILLIEB->' + mesg);
      } catch { }
    }*/
  }

  public async logout(reason?: string): Promise<void> {
    if (reason) {
      await this.logDebug('Logout : ' + reason);
    } else {
      await this.logDebug('Logout : no reason');
    }
    await this.clearUserData(false);

    setTimeout(() => {
      window.location.href = '/welcome'; // Redirects to the root URL
    }, 1000);
  }

  public async clearUserData(clearBiologin: boolean = true): Promise<void> {
    await this._userInfoService.setUserInfo(null);
    this.clearRefresh(); //terminate timers 

    const region = await this.secureStorageService.get(SecureStorageKey.CurrentRegion);
    const bioLogin = await this.secureStorageService.get(SecureStorageKey.BiometricLogin);
    await this.secureStorageService.clear();
    if (bioLogin && !clearBiologin) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, bioLogin);
    }
    if (region) {
      await this.secureStorageService.set(SecureStorageKey.CurrentRegion, region);
      await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    }
  }


  private tokenRefreshRetry = 0;

  private async refresh(timerRefresh: boolean): Promise<void> {
    try {
      const userInfo = await this._userInfoService.getUserInfo();
      if (userInfo) {
        const expired = await this.tokenExpired();
        if (!timerRefresh && !expired) {
          return;
        }
        if (!userInfo.refresh_token) {
          await this.logDebug("refresh token - no refresh token available go false");
          await this.logout('User token has no refresh token');
          return;
        }

        const env = await this._EnvironmentConfigService.EnsureNetworkConfigurationForToken();
        const refreshOptions: RefreshTokenRequestModel = {
          applicationId: env!.authenticateOptions!.appId!,
          refreshToken: userInfo.refresh_token!
        }

        let response: RefreshTokenResponseModel | null = null;


        try {

          this.tokenRefreshRetry++;
          response = await firstValueFrom(
            this._localAccountService.localAccountRefreshTokenPost({ body: refreshOptions })
              .pipe(timeout(this.REFRESH_TIMEOUT))
          );
          this.tokenRefreshRetry = 0;
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (this.tokenRefreshRetry > 6) {
            let retriesStr = +this.tokenRefreshRetry.toString();
            this.tokenRefreshRetry = 0;
            const err = error as any;
            if (err && err.error && err.error.message) {
              await this._PromptService.showNetworkConnectionError(error, 'refresh');
              await this.logout('REFRESH TOKEN failed ' + err.error.message + ' retries: ' +retriesStr);
              return;

            } else {
              await this._PromptService.showNetworkConnectionError(error, 'refresh '+ ' retries: ' +retriesStr);
              await this.logout('REFRESH TOKEN failed 2');
            }
            response = null;
          } else {
            setTimeout(async () => {
              await this.logDebug("REFRESH TOKEN failed - execture a retry " + this.tokenRefreshRetry.toString());
              await this.refreshToken(timerRefresh);
            });
            return;
          }
        }

        if (!response) {
          await this.setupRefreshTimer();
          return; //try another time
        }

        if (response.error) {
          await this.setupRefreshTimer();
        }

        //we have a bad token - logout the user.
        if (!response.access_token || response.access_token.length <= 0 || response.error) {
          setTimeout(async () => {
            await this.logDebug("REFRESH TOKEN failed - execture a retry returned error3 [" + JSON.stringify(response) + "] " + this.tokenRefreshRetry.toString());
            await this.refreshToken(timerRefresh);
          });
          return;
        }
        const tokenResponse = response as RefreshTokenResponseModel;
        if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
          const token = this.parseJwt(tokenResponse.access_token);
          // Convert `exp` to milliseconds and create a Date object for expiry
          const tokenExpiry = new Date(token.exp * 1000);
          // Subtract 2 minutes from the expiry time
          const renewTokenTime = new Date(tokenExpiry.getTime() - 2 * 60 * 1000);
          const tokenUser: UserData = {
            sub: token.oid,
            given_name: token.given_name,
            family_name: token.family_name,
            name: token.name,
            access_token: tokenResponse.access_token!,
            refresh_token: tokenResponse.refresh_token!,
            tokenExpiry: renewTokenTime.toISOString(),
            tokenClaims: token,
            email: token.userEmailAddress!,
            language: token.language,
            systemFunctions: token.systemFunctions,
            emailVerified: tokenResponse.emailVerified
          };


          await this._userInfoService.setUserInfo(tokenUser);
          await this.setUserLoggedInAsync();
          await this.logDebug("REFRESH TOKEN  user logged in " + JSON.stringify(tokenUser));
        }

      }
    } catch (error) {
      await this.setupRefreshTimer();
      setTimeout(async () => {
        await this.logDebug("REFRESH TOKEN failed - exception [" + JSON.stringify(error) + "]" + this.tokenRefreshRetry.toString());
        await this.refreshToken(timerRefresh);
      });
    }
  }


  private async setUserLoggedInAsync(): Promise<void> {

    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo) {
      await this.setupRefreshTimer();
    }
  }

  private refreshTimout: any | null = null;
  private clearRefresh() {
    if (this.refreshTimout) {
      clearTimeout(this.refreshTimout);
      this.refreshTimout = null;
    }
  }

  private async singleRefreshSetup() {

    let waitTimeSeconds = 30;
    const userInfo = await this._userInfoService.getUserInfo();
    if (!userInfo) {
      return;
    }

    // Get the current time in UTC
    const now = new Date();
    let end = now;

    // Parse the token expiry time as a UTC date
    if (userInfo.tokenExpiry) {
      end = new Date(userInfo.tokenExpiry);
      end = subMinutes(end, 10 - 9); //10 minutes in advance
    }

    // Calculate the difference in seconds between the token expiry and the current time
    const seconds = differenceInSeconds(end, now);

    // Adjust the wait time based on the time remaining before the token expires
    waitTimeSeconds = seconds;

    if (waitTimeSeconds <= 15)
      waitTimeSeconds = 15;

    // Clear any existing refresh interval and set a new one
    this.clearRefresh();
    this.refreshTimout = setTimeout(async () => {
      await this.refreshToken(true);
    }, waitTimeSeconds * 1000);

  }

  private refreshLock = new Semaphore(1);

  private async setupRefreshTimer() {
    const result = false;
    await this.refreshLock.callFunction(async () => {
      await this.singleRefreshSetup();
    }, []);
    return result;

  }

  public async DeleteProfile(): Promise<boolean> {
    try {
      await firstValueFrom(this._localAccountService.localAccountDeleteAccountDelete().pipe(timeout(this.NETWORK_TIMEOUT)));
      //logout and clear local storage
      await this.clearBiometricLogin();
      await this.logout();
      await this.secureStorageService.clear();

      return true;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'DeleteProfile');
      console.error('Unexpected error:', error);
      return false;
    }
  }

}
