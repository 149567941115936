import { Injectable } from '@angular/core';
import { AppLogModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { UserInfoService } from '../auth-service/user-info-service';
import { Capacitor } from '@capacitor/core';
import { VitalScoreProfileService } from 'src/app/api/proxy/vitalscore/vitalscore-services';
import { environment } from 'src/environments/environment';
import { firstValueFrom, timeout } from 'rxjs';
import { sharedConfig } from 'src/environments/shared-config';

@Injectable({
  providedIn: 'root',
})
export class SystemLogService {

  private NETWORK_TIMEOUT = 15000;
  private userId: string = '';

  private lastAcive: Date | null = null;
  private logEnabled: boolean = false;

  constructor(private _logService: VitalScoreProfileService,
    private _userInfoService: UserInfoService) {
  }

  private initialized = false;

  public async init(): Promise<void> {
    // Simulate some initialization logic (e.g., checking token, refreshing, etc.)
    this.initialized = true;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  enableLogging() {
    this.logEnabled = true;
  }

  async logMessage(message: string | null): Promise<void> {
    if (!this.logEnabled) {
      return;
    }
    const userInfo = await this._userInfoService.getUserInfo();
    if (message == null || message.length <= 0)
      return;

    if (this.userId == '') {
      if (userInfo && userInfo.email) {
        this.userId = userInfo.email;
      }
    }

    const msg: AppLogModel = {
      logMessage: message,
      userId: this.userId
    }

    const envModel = {
      platForm: Capacitor.getPlatform(),
      version: sharedConfig.version,
      build: sharedConfig.build,
      environment: environment.environmentName,
      userId: userInfo?.email
    }

    msg.userObject = JSON.stringify(envModel);

    try {
      const networkCall = this._logService.profileAppLogMessagePost({ body: msg });
      await firstValueFrom(networkCall.pipe(timeout(this.NETWORK_TIMEOUT)));

    } catch (error) {
      console.error(error);
    }

  }


  async logError(errorObject: unknown, statusText: string = 'error', title: string = 'error'): Promise<void> {
    let errorMessage = '';

    if(!errorObject)
      return;

    if (errorObject instanceof Error) {
      // If errorObject is an instance of Error, extract message and stack
      errorMessage = `${title}:${statusText}:-> Message: ${errorObject.message}, Stack: ${errorObject.stack}`;
    } else {
      // Fallback if errorObject is not an Error instance, try JSON.stringify
      errorMessage = `${title}:${statusText}:-> ${JSON.stringify(errorObject)}`;
    }

    await this.logMessage(errorMessage);
  }

 /* async appStateChanged(active: boolean): Promise<void> {

    if (!active && this.lastAcive) {
      var activeTime = new Date().getTime() - this.lastAcive.getTime();

      var expired = this._tokenService.tokenExpired();
      if (!expired) {
        try {
          var msg: TimeSpentLogModel = {
            appStarted: this.lastAcive.toISOString(),
            timeSpent: activeTime
          };

          var networkCall = this._logService.profileLogTimeSpentPost({ body: msg });
          await firstValueFrom(networkCall.pipe(timeout(this.NETWORK_TIMEOUT)));

        } catch (error) {
          console.error(error);
        }
      }
    }
    this.lastAcive = new Date();
  }*/

  
}


