import { Injectable } from '@angular/core';
import { UserData } from './user-data.model';
import { SystemFunctionType } from 'src/app/api/proxy/auth/authentication-models';
import { SystemFunctionTypeIndex } from './SystemFunctionTypeIndex';
import { EventService } from '../events/event.service';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {

  constructor(private _eventService: EventService,
    private _secureStorageService: SecureStorageService
  ) {

  }

  private initialized = false;

  public async init(): Promise<void> {
    // Simulate some initialization logic (e.g., checking token, refreshing, etc.)
    this.initialized = true;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public async setUserInfo(val: UserData | null) {
    if (val != null) {
      if (val?.tokenClaims.oid) {
        val.sub = val?.tokenClaims.oid;
        val.tokenClaims.sub = val?.tokenClaims.oid;
      }
    }   
    if (val) {
     await this._secureStorageService.set(SecureStorageKey.CurrentUser, JSON.stringify(val));
    } else {
     await this._secureStorageService.remove(SecureStorageKey.CurrentUser);
    }
    this._eventService.userUpdated.emit(val);
  }

  public async getUserInfo(): Promise<UserData | null> {
    const user = await this._secureStorageService.get(SecureStorageKey.CurrentUser);
      if(user){
        let userInfo = JSON.parse(user);
        return userInfo;
      }
    return null;
  }

  public async userIdAllowed(x: SystemFunctionType): Promise<boolean> {
    const userDetail = await this.getUserInfo();
    if (!userDetail) {
      return false;
    }
    const systemFunctions = userDetail?.tokenClaims?.systemFunctions;
    if (systemFunctions && systemFunctions.length > 0) {
      const ival = SystemFunctionTypeIndex[x];
      if (ival != null) {
        const hasAccess = systemFunctions.includes(ival.toString());
        return hasAccess;
      }
    }
    return false;
  }

  


}


