import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { EventService } from './services/events/event.service';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
import { StorageService } from './services/storage/storage.service';
import { SecureStorageKey } from './services/storage/models/secure-storage-key.enum';
import { UserInfoService } from './services/auth-service/user-info-service';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;

  // public showMenu = true;
  busyLoading = false;

  constructor(
    public servicesStartupService: ServicesStartupService,
    public navCtrl: NavController,
    private events: EventService,
    private renderer: Renderer2,
    private _storageService: StorageService,
    private _events: EventService,
    private _userService: UserInfoService
  ) {
  
  }

  async initializeApp() {
    this.events.loadingEvent.subscribe(async (loading: number) => {
      this.busyLoading = loading > 0;
    });

    await App.removeAllListeners();
    await App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".app").pop();
      if (slug) {
        try {
          let path = slug.toLowerCase();
          if (path.includes(environment.deepLinkURLBase!)) {
            path = path.replace(environment.deepLinkURLBase!, '');
            if (path.startsWith('/'))
              path = path.replace(/^./, "");
          }
          await this.navigateToDeepLink(path);
        } catch (e) {
          console.error(e);
        }
      }

    });

    await App.addListener('resume', async () => {

      try {
        await this.servicesStartupService.resumeApp();
        if (Capacitor.isNativePlatform()) {
          const usr = await this._userService.getUserInfo(); // if a user is logged in, check time paused

          if (usr && usr.timeAppPaused) {
            const currentTime = new Date();
            const timeAppPaused = new Date(usr.timeAppPaused);
            const timeDifference = (currentTime.getTime() - timeAppPaused.getTime()) / 1000 / 60; // convert to minutes

            if (timeDifference > 1) {
              setTimeout(async () => {
                await this.navCtrl.navigateRoot("/");
              }, 1000);
            }
          }
        }
        this.events.appResumed.emit();

      } catch (error) {
        console.error('Error on app resume:', error);
        this.events.appResumed.emit();
      }


    });

    await App.addListener('pause', async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          const usr = await this._userService.getUserInfo();//if a user is logged in go back home
          if (usr) {
            usr.timeAppPaused = new Date();
            await this._userService.setUserInfo(usr);
          }
        } catch (error) {
          console.error('Error on app pause:', error);
        }
        this.events.appPaused.emit();
      }
    });

    await this.servicesStartupService.initServices();
  }


  async ngOnInit(): Promise<void> {
    await this.initializeApp();
    if (!Capacitor.isNativePlatform()) {
      this.renderer.addClass(document.body, 'web-platform');
    }
  }

  async navigateToDeepLink(path: string): Promise<void> {
    const linkPath = {
      path: path
    }
    const pathJSON = JSON.stringify(linkPath);
    await this._storageService.set(SecureStorageKey.DeeplinkPath, pathJSON);
    this._events.appDeeplinkEvent.emit(path);
  }

}
