import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'viva-info-popup',
  templateUrl: './viva-info-popup.component.html',
  styleUrls: ['./viva-info-popup.component.scss'],
})
export class VivaInfoPopupComponent {

  @Input() message?: string;

  constructor(private modalController: ModalController) { }


  async dismiss() {
    await this.modalController.dismiss();
  }

}
