import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'viva-error-popup',
  templateUrl: './viva-error-popup.component.html',
  styleUrls: ['./viva-error-popup.component.scss'],
})
export class VivaErrorPopupComponent implements OnInit {

  @Input() message?: string;

  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  async dismiss() {
    await this.modalController.dismiss();
  }

}
