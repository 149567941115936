import { EventEmitter, Injectable } from '@angular/core';
import { UserData } from '../auth-service/user-data.model';
import { AppAccessType, HealthScoreResponseModel, ProfileModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { LastFaceScan } from '../face-scan-service/LastFaceScan';
import { SessionConfigResponseModel, StatisticsModel, SummaryModel } from 'src/app/api/proxy/wearables/wearables-models';
import { ILocalAppEnvironment } from '../environment-config-service/environment-config.service';
import { HealthScoreHistory } from '../health-score-service/health-score-history';
import { ProfileScanHistoryModel } from 'src/app/api/proxy/facescan/facescan-models';
import { OrganizationTokenModel } from 'src/app/api/proxy/organization/organization-models';
import { NotificationItemWithArticle } from '../one-signal-service/one-signal.service';
import { TokenService } from '../token/token.service';

@Injectable({
	providedIn: 'root'
})

export class EventService {
	public healthScoreUpdated: EventEmitter<HealthScoreResponseModel> = new EventEmitter<HealthScoreResponseModel>();
	public faceScanUpdated: EventEmitter<LastFaceScan> = new EventEmitter<LastFaceScan>();
	public myProfileUpdated: EventEmitter<ProfileModel> = new EventEmitter<ProfileModel>();
	
	public wearableDialySummaryUpdated: EventEmitter<StatisticsModel> = new EventEmitter<StatisticsModel>();
	public wearableSummaryUpdated: EventEmitter<SummaryModel> = new EventEmitter<SummaryModel>();

	public wearableConfigUpdted: EventEmitter<SessionConfigResponseModel> = new EventEmitter<SessionConfigResponseModel>();
	public appDeeplinkEvent: EventEmitter<string> = new EventEmitter<string>();
	public appPaused: EventEmitter<void> = new EventEmitter<void>();
	public appResumed: EventEmitter<void> = new EventEmitter<void>();
	public pageFocused: EventEmitter<AppAccessType> = new EventEmitter<AppAccessType>();
	public notificationCount: EventEmitter<number> = new EventEmitter<number>();
	public notificationHistoryUpdated: EventEmitter<Array<NotificationItemWithArticle>> = new EventEmitter<Array<NotificationItemWithArticle>>();
	public environmentLoaded: EventEmitter<ILocalAppEnvironment> = new EventEmitter<ILocalAppEnvironment>();
	public userUpdated: EventEmitter<UserData | null> = new EventEmitter<UserData | null>();
	public loadingEvent: EventEmitter<number> = new EventEmitter<number>();
	public healthScoreHistoryUpdated: EventEmitter<HealthScoreHistory> = new EventEmitter<HealthScoreHistory>();
	public faceScanHistoryUpdated: EventEmitter<ProfileScanHistoryModel> = new EventEmitter<ProfileScanHistoryModel>();
	public notifyBootstrapCompleted: EventEmitter<TokenService> = new EventEmitter<TokenService>();
	public organizationConfigLoaded: EventEmitter<OrganizationTokenModel> = new EventEmitter<OrganizationTokenModel>();
	private initialized = false;

	public async init(): Promise<void> {
		// Simulate some initialization logic (e.g., checking token, refreshing, etc.)
		this.initialized = true;
	}

	public isInitialized(): boolean {
		return this.initialized;
	}
}
