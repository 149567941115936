export const environment = {
	deepLinkURLBase:'https://longevity.alula.health',
	appEnvironmentBaseUrl:'https://api.alulahealth.com/health/environment',
	
	dislaimerUrl:'https://www.alulatechnologies.com/VivaScore_Disclaimer',
	welcomeBackground: '/assets/v2/welcome/background-longevity.png',

	oneSignalAppId: '0b1b315e-e56f-4450-aa57-b1501176cf5a',
	environmentName: 'longevity',
	production: true,	
	defaultLanguage: 'en',

	countryCode: 'us',
	organizationId: 'f585a2a8-44be-4c07-732c-08dd0327bb79',
	scanFriendAllowed: false,
	enableMobileRegistation: true,
	enableWebRegistation: true,
	
};
