import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './shared/guards/login/login.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
	{
		path: 'face-scan-web-result',
		loadChildren: () => import('./pages/face-scan-web-result/face-scan-web-result.module')
			.then(m => m.FaceScanWebResultPageModule)
	},
	{
		path: 'face-scan-teams-result',
		loadChildren: () => import('./pages/face-scan-web-result/face-scan-web-result.module')
			.then(m => m.FaceScanWebResultPageModule)
	},
	{
		path: 'welcome',
		loadChildren: () => import('./pages/welcome/welcome.module')
			.then(m => m.WelcomeModule)
	},

	{
		path: 'v2/welcome/intro-page',
		loadChildren: () => import('./pages/v2/welcome/intro-page/intro-page.module').then(m => m.IntroPagePageModule)
	},
	{
		path: 'v2/registration/start',
		loadChildren: () => import('./pages/v2/registration/start/start.module').then(m => m.StartPageModule)
	},
	{
		path: 'v2/registration/confirm-code',
		loadChildren: () => import('./pages/v2/registration/confirm-code/confirm-code.module').then(m => m.ConfirmCodePageModule)
	},
	{
		path: 'v2/registration/profile-detail',
		loadChildren: () => import('./pages/v2/registration/profile-detail/profile-detail.module').then(m => m.ProfileDetailPageModule)
	},

	{
		path: 'v2/registration/confirm-otp',
		loadChildren: () => import('./pages/v2/registration/confirm-otp/confirm-otp.module').then(m => m.ConfirmOtpPageModule)
	},
	{
		path: 'v2/registration/create-password',
		loadChildren: () => import('./pages/v2/registration/create-password/create-password.module').then(m => m.CreatePasswordPageModule)
	},
	{
		path: 'v2/registration/create-account',
		loadChildren: () => import('./pages/v2/registration/create-account/create-account.module').then(m => m.CreateAccountPageModule)
	},
	{
		path: 'v2/registration/get-vivascore',
		loadChildren: () => import('./pages/v2/registration/get-vivascore/get-vivascore.module').then(m => m.GetVivascorePageModule)
	},
	{
		path: 'v2/registration/consent',
		loadChildren: () => import('./pages/v2/registration/consent/consent.module').then(m => m.ConsentPageModule)
	},
	{
		path: 'v2/account/login',
		loadChildren: () => import('./pages/v2/account/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'v2/account/reset-password',
		loadChildren: () => import('./pages/v2/account/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
	},

	{
		path: 'control-demo',
		loadChildren: () => import('./pages/control-demo/control-demo.module')
			.then(m => m.ControlDemoModule)
	},

	/***************************************************/
	/*             VivaScore Lite Routes               */
	/***************************************************/
	{
		path: 'viva-lite/access-code',
		loadChildren: () => import('./pages/viva-lite/access-code/access-code.module').then(m => m.AccessCodePageModule)
	},
	{
		path: 'viva-lite/consent-code',
		loadChildren: () => import('./pages/viva-lite/consent/vivalite-consent.module').then(m => m.VivaLiteConsentPageModule)
	},
	{
		path: 'viva-lite/scan-input',
		loadChildren: () => import('./pages/viva-lite/scan-input/vivalite-scan-input.module').then(m => m.VivaLiteScanInputPageModule)
	},
	{
		path: 'viva-lite/scan-tips',
		loadChildren: () => import('./pages/viva-lite/scan-tips/vivalite-scan-tips.module').then(m => m.VivaLiteScanTipsPageModule)
	},
	{
		path: 'viva-lite/scan-scanning',
		loadChildren: () => import('./pages/viva-lite/scan-scanning/vivalite-scan-scanning.module').then(m => m.VivaLiteScanScanningPageModule)
	},
	{
		path: 'viva-lite/scan-completed',
		loadChildren: () => import('./pages/viva-lite/scan-completed/vivalite-scan-error.module').then(m => m.VivaLiteScanErrorPageModule)
	},
	{
		path: 'viva-lite/scan-result',
		loadChildren: () => import('./pages/viva-lite/scan-result/vivalite-result.module').then(m => m.VivaLiteScanResultPageModule)
	},
	{
		path: 'viva-lite/biometric-detail',
		loadChildren: () => import('./pages/viva-lite/biometric-detail/vivalite-biometric-detail.module').then(m => m.VivaLiteBiometricDetailModule)
	},
	{
		path: 'viva-lite/faq',
		loadChildren: () => import('./pages/viva-lite/faq/vivalite-faq.module').then(m => m.VivaLiteFaqModule)
	},
	/***************************************************/
	/*                  Auth Pages                     */
	/***************************************************/

	{
		path: 'v2/health-check/scan-input',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/v2/health-check/scan-input/scan-input.module').then(m => m.ScanInputPageModule)
	},
	{
		path: 'v2/health-check/scan-completed',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/v2/health-check/scan-error/scan-error.module').then(m => m.ScanErrorPageModule)
	},
	{
		path: 'v2/health-check/scan-tips',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/v2/health-check/scan-tips/scan-tips.module').then(m => m.ScanTipsPageModule)
	},
	{
		path: 'v2/health-check/scan-intro',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/v2/health-check/scan-intro/scan-intro.module').then(m => m.ScanIntroPageModule)
	},
	{
		path: 'v2/health-check/scan-scanning',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/v2/health-check/scan-scanning/scan-scanning.module').then(m => m.ScanScanningPageModule)
	},
	{
		path: 'tab',
		loadChildren: () => import('./pages/teams/teams.module')
			.then(m => m.TeamsModule)
	},
	{
		path: 'deleteAccount',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/delete-account/delete-account.module')
			.then(m => m.DeleteAccountModule)
	},	
	{
		path: 'wearable-connected',
		loadChildren: () => import('./pages/wearable-connected/wearable-connected.module')
			.then(m => m.WearableConnectedPageModule),
	},
	{
		path: 'wearable-connected/client_uuid/:clientId/user_id/:userId',
		loadChildren: () => import('./pages/wearable-connected/wearable-connected.module')
			.then(m => m.WearableConnectedPageModule),
	},
	
	{
		path: '',
		canLoad: [LoginGuard],
		loadChildren: () => import('./pages/home/home.routes').then((m) => m.routes),
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }


