export class Semaphore {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentRequests: any[];
	runningRequests: number;
	maxConcurrentRequests: number;
	isProcessing: boolean; // Track if a request is currently being processed

	constructor(maxConcurrentRequests = 1) {
		this.currentRequests = [];
		this.runningRequests = 0;
		this.maxConcurrentRequests = maxConcurrentRequests;
		this.isProcessing = false; // Initialize the processing flag as false
	}

	/**
	 * Returns a Promise that will eventually return the result of the function passed in.
	 * Subsequent calls will be queued and executed sequentially.
	 * @param {*} fnToCall Function to call with controlled concurrency
	 * @param  {...any} args Arguments to pass to the function
	 * @returns Promise resolving with the function's result
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public callFunction(fnToCall: any, ...args: any[]) {
		return new Promise((resolve, reject) => {
			this.currentRequests.push({
				resolve,
				reject,
				fnToCall,
				args,
			});
			this.tryNext();
		});
	}

	tryNext(): void {
		if (this.isProcessing || this.runningRequests >= this.maxConcurrentRequests || !this.currentRequests.length) {
			return;
		}

		this.isProcessing = true; // Set the processing flag

		const { resolve, reject, fnToCall, args } = this.currentRequests.shift();
		this.runningRequests++;

		const req = fnToCall(...args);
		req
			.then((res: any) => resolve(res))
			.catch((err: any) => reject(err))
			.finally(() => {
				this.runningRequests--;
				this.isProcessing = false; // Reset the processing flag
				this.tryNext(); // Process the next request in the queue
			});
	}

	/**
	 * Clears the semaphore and resets it to the initial state.
	 * Discards all pending and running requests.
	 */
	public reset(): void {
		this.currentRequests = [];
		this.runningRequests = 0;
		this.isProcessing = false; // Ensure the processing flag is reset
	}
}