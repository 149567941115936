import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';
import { AuthService } from '../auth-service/auth.service';
import { Capacitor } from '@capacitor/core';
import { StorageService } from '../storage/storage.service';
import { EventService } from '../events/event.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesStartupService {


  constructor(
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _secureStorageService: SecureStorageService,
    private _authService: AuthService,

    private _tokenService: TokenService,
    private _userInfoService: UserInfoService,
    private _teamsService: TeamsService,
    private _storageService: StorageService,
    private _eventService: EventService
  ) {

  }

  private async bootStrap(): Promise<void> {
    await this._authService.init();
    await this._tokenService.init();
    await this._userInfoService.init();
    await this._teamsService.init();
    await this._secureStorageService.init();
    await this._storageService.init();
    await this._EnvironmentConfigService.init();
    await this._eventService.init();

    this._eventService.notifyBootstrapCompleted.emit(this._tokenService);

    const redirectRoute = this.getParameterByName('route');
    if (redirectRoute) {
      const r = {
        route: redirectRoute
      };
      const jsonRoute = JSON.stringify(r);
      await this._secureStorageService.set(SecureStorageKey.RedirectRoute, jsonRoute);

    }
  }

  public GetTokenService() : TokenService{
    return this._tokenService;
  }

  public async initServices(): Promise<void> {
    if (Capacitor.getPlatform() === "android")
      await SplashScreen.hide();

    await SplashScreen.show({ autoHide: true });
    await this.bootStrap();
    try {
      await this._EnvironmentConfigService.EnsureNetworkConfiguration();
    } finally {
      await SplashScreen.hide();

    }
  }

  public async resumeApp(): Promise<boolean> {
    await this._secureStorageService.init();
    await this._authService.init();
    return true;
  }

  getParameterByName(name: string, url = window.location.href): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
